
















































































































































import { Component, Vue } from "vue-property-decorator";
import { GaodeService, OrganizationService, PackageService, OrderService, FamilyService } from "src/services";
import { Toast, Dialog } from "vant";
import dayjs from "dayjs";
import { CHECK_STATUS, ORDER_STATUS } from "src/enums";
import { EnumUtils, tools } from "src/utils";
declare let WeixinJSBridge: any 
declare let document: any


@Component
export default class OfteUser extends Vue
{
    /**
     * 时间处理
     * @private
     * @returns 
     */
    private dayjs: any = dayjs;

    /**
     * 是否显示日期
     * @private
     * @returns 
     */
    private showDate: boolean = false;

    /**
     * 自选项目选中
     * @private
     * @returns any
     */
    private fixedCollapseActive: any = [];

    /**
     * 自选项目选中
     * @private
     * @returns any
     */
    private collapseActiveNames: any = [];

    /**
     * 订单详情
     * @private
     * @returns 
     */
    private orderDetail: any = {};
    
    /**
     * 根据预约日期判断能否取消，(今天12点前或前一天不能取消)
     * @private
     * @returns boolean
     */
    private get isCancel(): boolean
    {
        let orderDate = this.orderDetail.orderModel.reserveTime;
        // 判断体检日期是不是明天
        let isTomorrow = dayjs(orderDate).add(1,"day").format("YYYY-MM-DD") === dayjs().format("YYYY-MM-DD");
        // 判断是否是今天
        let isToDay = dayjs(orderDate).format("YYYY-MM-DD") === dayjs().format("YYYY-MM-DD");
        if(isTomorrow)
        {
            return false;
        }
        else if(isToDay && (+dayjs(orderDate).format("hh") < 12))
        {
            return false;
        }

        return  true;
    }

    /**
     * 订单id
     * @private
     * @returns number
     */
    private get orderId(): number
    {
        if(this.$route.params && this.$route.params.id)
        {
           return +this.$route.params.id;
        }

        return null;
    }

    /**
     * 组件创建钩子
     * @private
     * @returns void
     */
    private created(): void
    {
        if(this.orderId)
        {
            this.getOrderDetail(this.orderId);
        }
        
    }

    /**
     * 获取订单详情
     * @private
     * @param {params}
     * @returns {Promise<void>}
     */
    private async getOrderDetail(orderId: number): Promise<void>
    {
        try
        {
            let {content: result} =  await OrderService.instance.getOrderDetail(orderId);

            if(result)
            {
                this.orderDetail = result.data;
            }
        }
        catch(err)
        {
            Toast(err);
        }
        
    }

    /**
     * 继续支付
     * @private
     * @param {params}
     * @returns {Promise<void>}
     */
    private async proceedPay(): Promise<void>
    {
        try
        {
            let {content: result} =  await OrderService.instance.proceedPay(this.orderId);

            if(result)
            {
                this.onBridgeReady(result.data)
            }
        }
        catch(err)
        {
            Toast(err);
        }
        
    }

    /**
     * 付款
     * @private
     * @returns void
     */
    private onBridgeReady(model: any): void
    {
        let data =  model.payRes && model.payRes.parameter && JSON.parse(model.payRes.parameter)
        let self: any = this;
        WeixinJSBridge.invoke(
            "getBrandWCPayRequest",
            {
                appId: data.appId, // 公众号名称，由商户传入
                timeStamp: data.timeStamp, // 时间戳，自1970年以来的秒数
                nonceStr: data.nonceStr, // 随机串
                package: data.package, //
                signType: data.signType, // 微信签名方式:
                paySign: data.paySign // 微信签名
            },
            function(res)
            {
                if (res.err_msg === "get_brand_wcpay_request:ok")
                {
                    self.getOrderDetail(this.orderId);
                    Toast.success("支付成功");
                    // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                }
                else if(res.err_msg === "get_brand_wcpay_request:cancel")
                {
                    // 取消支付
                    self.getOrderDetail(this.orderId);
                }
                else
                {
                    self.getOrderDetail(this.orderId);
                    Toast.fail("支付失败");
                }
            }
        );
    }

    /**
     * 显示日期弹框
     * @private
     * @returns void
     */
    private showDateModal(): void
    {
        this.showDate = true;
    }

    /**
     * 确定改约
     * @private
     * @returns void
     */
    private onDateConfirm(date: any): any
    {
        if(!date) return;

        let data = dayjs(date).format("YYYY-MM-DD");
        Dialog.confirm({
        title: "改约日期",
        message: `是否确定改约${data}体检`
        })
        .then(async() => {
            try
            {
                let {content: result} =  await OrderService.instance.modifiedOrderDate(this.orderId, data);

                this.getOrderDetail(this.orderId);
                Toast({
                    duration: 1000,
                    message: "改约成功"
                });
                this.showDate = false;
            }
            catch(err)
            {
                Toast(err);
            }
        });
    }
    
    /**
     * 取消订单
     * @private
     * @param {params}
     * @returns {Promise<void>}
     */
    private onCancelOrder(): void
    {
        Dialog.confirm({
        title: "取消订单",
        message: "是否确定取消订单"
        })
        .then(async() => {
            try
            {
                let {content: result} =  await OrderService.instance.cancelOrder(this.orderId);
                Toast.success("取消成功!");
                this.getOrderDetail(this.orderId);
            }
            catch(err)
            {
                Toast(err);
            }
        });
    }

    /**
     * 显示提示
     * @private
     * @returns void
     */
    private showTip(remark: string): void
    {
        Dialog.alert({
            messageAlign: "left",
            confirmButtonColor: "#43B8A1",
            message: remark,
            confirmButtonText: "确定"
        });
    }

}
